<template>
  <div style="
      background-color: rgba(33, 34, 68, 1);
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    ">
    <van-row>
      <!-- <van-col
        span="24"
        style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 0.8125rem;
        "
      >
        {{ $t("tongji") }} {{ token_name }}
      </van-col> -->

      <!-- USDT余额显示 -->
      <!-- <van-col v-if="buy_btn" span="24" style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 1.875rem;
        ">
        {{ (token_number - 0).toFixed(2) }}
        <span style="font-size: 1.875rem; color: rgba(112, 128, 179, 1)">{{
          "USDT"
          }}</span>
      </van-col> -->

      <!-- RACE余额显示 -->
      <!-- <van-col v-if="sell_btn" span="24" style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 1.875rem;
        ">
        {{ (token_number - 0).toFixed(2) }}
        <span style="font-size: 1.875rem; color: rgba(112, 128, 179, 1)">{{
          token_name
          }}</span>
      </van-col> -->
    </van-row>
  </div>

  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
        ">
        <van-row>
          <van-col span="12">
            {{ $t("address") }}
          </van-col>
          <van-col span="12" style="text-align: right">
            {{
              address.substring(0, 4) +
              "****" +
              address.substring(address.length - 5)
            }}
          </van-col>
        </van-row>
      </van-col>

      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>

          <van-col span="12">
            <!-- 6b54ee 363762-->
            <van-button :color="buy_btn ? '#6b54ee' : '#363762'" size="large" style="border-radius: 10px;width: 90%;"
              @click="buy_btn = true; sell_btn = false;">
              {{ $t("touzi") }}</van-button>
          </van-col>
          <van-col span="12">
            <van-button :color="sell_btn ? '#6b54ee' : '#363762'" size="large" style="border-radius: 10px;width: 90%;"
              @click="buy_btn = false; sell_btn = true;">
              {{ $t("shuhui") }}</van-button>
          </van-col>

        </van-row>
      </van-col>

      <!-- ===投资表单=== -->
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        " v-if="buy_btn">
        <van-row>
          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="4">
                {{ $t("yue") }}
              </van-col>
              
              <van-col span="20" style="text-align: right">
                {{ "0.00" }}
                <span style="color: rgba(112, 128, 179, 1)">USDT</span>
              </van-col>
            </van-row>
          </van-col>

          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="24">
                {{ $t("shuliang") }}
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                      ">
                      <span style="margin-left: 5px">USDT</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px"
                        @click="change_price = token_number">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" style="margin-top: 20px">
            <van-button color="rgba(107,84,238,1)" size="large" style="border-radius: 10px" @click="auth">{{
              $t("lijitouzi")
            }}</van-button>
          </van-col>
          <van-col span="24" style="margin-top: 20px">
            <van-button color="#363762" size="large" style="border-radius: 10px" @click="goEarning">{{
              $t("touzijilu")
              }}</van-button>
          </van-col>
        </van-row>
      </van-col>
      <!-- ###投资表单 END### -->

      <!-- === 赎回表单 === -->

      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        " v-if="sell_btn">
        <van-row>
          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="4">
                {{ $t("yue") }}
              </van-col>
              <van-col span="20" style="text-align: right">
                {{ "0.00" }}
                <span style="color: rgba(112, 128, 179, 1)">RACE</span>
              </van-col>
            </van-row>
          </van-col>

          <van-col span="24" style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
            <van-row>
              <van-col span="24">
                {{ $t("shuliang") }}
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                      ">
                      <span style="margin-left: 5px">RACE</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px"
                        @click="change_price = token_number">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" style="margin-top: 20px">
            <van-button color="rgba(107,84,238,1)" size="large" style="border-radius: 10px" @click="auth">{{
              $t("lijishuhui")
            }}</van-button>
          </van-col>
          <van-col span="24" style="margin-top: 20px">
            <van-button color="#363762" size="large" style="border-radius: 10px" @click="goEarning">{{
              $t("shuhuijilu")
              }}</van-button>
          </van-col>
        </van-row>
      </van-col>

      <!-- ### 赎回表单 END### -->



    </van-row>
  </div>
  <!-- <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <van-row>
      <van-col
        span="24"
        style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
        "
      >
        <van-row>
          <van-col span="12">
            {{ $t("address") }}
          </van-col>
          <van-col span="12" style="text-align: right">
            {{
              address.substring(0, 4) +
              "****" +
              address.substring(address.length - 5)
            }}
          </van-col>
        </van-row>
      </van-col>
      <van-col
        span="24"
        style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        "
      >
        <van-row>
          <van-col
            span="24"
            style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            "
          >
            <van-row>
              <van-col span="12">
                {{ $t("duihuanbili") }}
              </van-col>
              <van-col span="12" style="text-align: right">
                1{{ token_name }}={{ token_price }}
                <span style="color: rgba(112, 128, 179, 1)">USDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col
            span="24"
            style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            "
          >
            <van-row>
              <van-col span="12">
                {{ $t("keduihuan") }}
              </van-col>
              <van-col span="12" style="text-align: right">
                ≈ 0.00 <span style="color: rgba(112, 128, 179, 1)">USDT</span>
              </van-col>
            </van-row>
          </van-col>
          <van-col
            span="24"
            style="
              padding: 20px 10px;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            "
          >
            <van-row>
              <van-col span="24">
                {{ $t("duihuanshuliang") }}
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input
                      type="number"
                      style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      "
                      placeholder="0.00"
                      v-model="change_price"
                    />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div
                      style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;
                      "
                    >
                      <img
                        :src="require('@/assets/image/USDT.png')"
                        alt=""
                        style="width: 16px; height: 16px"
                      />
                      <span style="margin-left: 5px">USDT</span>
                      <van-button
                        color="rgba(15,9,0,1)"
                        size="mini"
                        round
                        style="margin-left: 5px"
                        >MAX</van-button
                      >
                    </div>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-button color="rgba(107,84,238,1)" size="large">{{
          $t("lijiduihuan")
        }}</van-button>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-button color="#363762" size="large">{{
          $t("duihuanjilu")
        }}</van-button>
      </van-col>
    </van-row>
  </div> -->
  <!-- <div style="margin-top: 20px; background-color: #212244; padding: 30px">
    <van-row>
      
      <van-col span="24" style="margin-top: 20px">
        <van-row gutter="20">
          <van-col span="4">
            <img
              :src="require('@/assets/image/info1.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
          <van-col span="4">
            <img
              :src="require('@/assets/image/info2.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
          <van-col span="4">
            <img
              :src="require('@/assets/image/info3.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
          <van-col span="4">
            <img
              :src="require('@/assets/image/info4.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
          <van-col span="4">
            <img
              :src="require('@/assets/image/info5.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
          <van-col span="4">
            <img
              :src="require('@/assets/image/info6.png')"
              alt=""
              style="height: 32px; width: 32px"
            />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div> -->
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "change",
  data() {
    return {
      change_price: 0.0,
      address: "",
      token_name: "",
      token_price: "",
      token_number: 0,
      buy_btn:true,
      sell_btn:false,
    };
  },
  methods: {
    goEarning() {
      this.$router.push({ path: "/earning" });
    },
    async getTrcWallet() {
      this.address = sessionStorage.getItem("address");
    },
    getInfo() {
      this.$request.post("nft/home/getContractInfo", "").then((res) => {
        if (res.data.code === 200) {
          this.token_name = res.data.result.symbol;
          this.token_price = res.data.result.price;
        }
      });
    },
    getMoney() {
      this.$request
        .post("nft/home/getMoney", { address: this.address })
        .then((res) => {
          if (res.data.code === 200) {
            this.token_number = res.data.result.number;
          }
        });
    },
    // 兑换操作
    async auth() {
      return this.$toast(this.$t("zanweikaifang"));
      if (this.change_price == 0) {
        return this.$toast(this.$t("amountError"));
      }
      this.$request
        .post("/nft/home/exchangeUsdt", {
          amount: this.change_price,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.getMoney();
            return this.$toast(res.data.message);
          } else {
            return this.$toast(res.data.message);
          }
        });
    },
  },
  mounted() {
    this.getTrcWallet();
    this.getInfo();
    this.getMoney();
  },
};
</script>

<style scoped>
</style>
