<template>
  <div style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #212244;
    ">
    <van-row align="center">
      <van-col span="6">
        <img :src="require('@/assets/image/index_RACE.png')" alt="" style="width: 64px; height: 64px; border-radius: 10px" />
      </van-col>
      <van-col span="18" style="font-size: 16px">
        {{
        address.substring(0, 4) +
        "****" +
        address.substring(address.length - 5)
        }}
      </van-col>
    </van-row>
  </div>
  <div style="padding: 30px; box-sizing: border-box">
              <!-- === 可提取 === -->

    <van-row>
      <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
        <van-row>
          <van-col span="24" style="color: #ffffff; font-size: 0.8125rem">
            {{ $t("ketiqu") }}
          </van-col>
          <!-- <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("wakuangbnianlilv") }}
                  </van-col> -->
        </van-row>
        <van-row style="margin-top: 20px">
          <van-col span="10">
            <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
              0 USDT
            </div>
          </van-col>
          <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
            <van-icon name="warning-o" />
          </van-col>
          <van-col span="10">
            <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
              0 RACE
            </div>
          </van-col>
          <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
            <van-icon name="warning-o" />
          </van-col>
        </van-row>
      </van-col>
    </van-row>
              <!-- ### 可提取 END ### -->
    <van-row>
      <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
                <van-row>
                  <van-col span="24" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("yishouyi") }}
                  </van-col>
                  <!-- <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("wakuangbnianlilv") }}
                  </van-col> -->
                </van-row>
                <van-row style="margin-top: 20px">
                  <van-col span="10">
                    <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      0 USDT
                    </div>
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                  <van-col span="10">
                    <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      0 RACE
                    </div>
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                </van-row>
              </van-col>
    </van-row>
  </div>

  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <van-row>
      <van-col span="12">
        <!-- 6b54ee 363762-->
        <van-button :color="usdt_tibibox?'#6b54ee':'#363762'" size="large" 
        style="border-radius: 10px;width: 90%;" @click="usdt_tibibox=true;race_tibibox=false;">
          {{ $t("tibi") }} USDT</van-button>
      </van-col>
      <van-col span="12">
        <van-button :color="race_tibibox?'#6b54ee':'#363762'" size="large" 
        style="border-radius: 10px;width: 90%;" @click="usdt_tibibox=false;race_tibibox=true;">
          {{ $t("tibi") }} RACE</van-button>
      </van-col>
    </van-row>
  </div>

  <!-- === USDT提币 === -->
  <div v-show="usdt_tibibox" style="width: 100%; padding: 0 30px 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="24" style="padding: 20px 10px; box-sizing: border-box">
            <van-row>
              <van-col span="24"> {{ $t("tibishuliang") }}</van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      ">
                      <span style="margin-left: 5px">USDT</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px"
                        @click="change_price = token_number">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="rgba(107,84,238,1)" size="large" 
                style="border-radius: 10px"
                @click="auth">{{ $t("tibi") }}</van-button>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="#363762" size="large" 
                style="border-radius: 10px"
                @click="goTibi"> {{
                  $t("tibijilu")
                  }}</van-button>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <!-- ### USDT提币 END ### -->

  <!-- === RACE提币 === -->
  <div v-show="race_tibibox" style="width: 100%; padding: 0 30px 30px; box-sizing: border-box">
    <van-row>
      <van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="24" style="padding: 20px 10px; box-sizing: border-box">
            <van-row>
              <van-col span="24"> {{ $t("tibishuliang") }}</van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="8">
                    <input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" />
                  </van-col>
                  <van-col span="16" style="text-align: right">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      ">
                      <span style="margin-left: 5px">RACE</span>
                      <van-button color="#f90" size="mini" round style="margin-left: 5px"
                        @click="change_price = token_number">Max</van-button>
                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="rgba(107,84,238,1)" size="large" 
                style="border-radius: 10px"
                @click="auth">{{ $t("tibi") }}</van-button>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button color="#363762" size="large" 
                style="border-radius: 10px"
                @click="goTibi">{{
                  $t("tibijilu")
                  }}</van-button>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <!-- ### RACE提币 END ### -->

  <div style="width: 100%; padding: 30px; box-sizing: border-box">
    <div style="
        background: linear-gradient(318.46deg, #6b54eb 32.62%, #d080ff);
        border-radius: 20px;
        padding: 20px 0;
        box-sizing: border-box;
      ">
      <van-row>
        <van-col span="6" style="text-align: center">
          <img :src="require('@/assets/image/invite1.png')" alt="" style="height: 44px; width: 44px" />
        </van-col>
        <van-col span="12">
          <van-row>
            <van-col span="24" style=""> {{ $t("yaoqingpengyou") }}</van-col>
            <van-col span="24" style="margin-top: 15px">
              {{ $t("zhuanquyongjin") }}</van-col>
          </van-row>
        </van-col>
        <van-col span="6">
          <van-button color="#ffffff" round style="color: #7080b3" @click="this.$router.push('/invite')">{{
            $t("yaoqing") }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
    ">
    <van-row>
      <van-col span="12" style="font-size: 1.25rem">
        {{ $t("wodeshouyi") }}</van-col>
      <van-col span="12" style="text-align: right">
        <van-button color="#372d3d" style="color: #f90; padding: 10px 20px" size="small" round @click="goEarning">{{
          $t("chakanquanbu") }}</van-button>
      </van-col>
    </van-row>
    <div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px">
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
          RACE
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: right">
          RACE
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col span="8">
          <countTo :startVal="0" :endVal="utmsTotal" :duration="3000"></countTo>
        </van-col>
        <van-col span="8" style="text-align: center">
          <countTo :startVal="0" :endVal="usdtTodayTotal" :duration="3000"></countTo>
        </van-col>
        <van-col span="8" style="text-align: right">
          <countTo :startVal="0" :endVal="utmsTodayTotal" :duration="3000"></countTo>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px">
        <van-col span="8"> {{ $t("quanbushouru") }}</van-col>
        <van-col span="8" style="text-align: center">
          {{ $t("jinrishouyi") }}</van-col>
        <van-col span="8" style="text-align: right">
          {{ $t("jinrishouyi") }}</van-col>
      </van-row>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
      margin-top: 20px;
    ">
    <van-row>
      <van-col span="12" style="font-size: 1.25rem">
        {{ $t("wodetuandui") }}</van-col>
      <van-col span="12" style="text-align: right">
        <van-button color="#372d3d" style="color: #f90; padding: 10px 20px" size="small" round @click="goTeam">
          {{ $t("chakanquanbu") }}</van-button>
      </van-col>
    </van-row>
    <div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
      <van-row style="margin-top: 10px">
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
          {{ $t("renshu") }}
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
          USDT
        </van-col>
        <van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: right">
          RACE
        </van-col>
      </van-row>
      <van-row style="margin-top: 10px">
        <van-col span="8">
          <countTo :startVal="0" :endVal="totalPeople" :duration="3000"></countTo>
        </van-col>
        <van-col span="8" style="text-align: center">
          <countTo :startVal="0" :endVal="usdtTodayPeople" :duration="3000"></countTo>
        </van-col>
        <van-col span="8" style="text-align: right">
          <countTo :startVal="0" :endVal="utmsTodayPeople" :duration="3000"></countTo>
        </van-col>
      </van-row>
      <van-row style="margin-top: 20px">
        <van-col span="8"> {{ $t("suoyouxiaji") }}</van-col>
        <van-col span="8" style="text-align: center">
          {{ $t("jinrishouyi") }}</van-col>
        <van-col span="8" style="text-align: right">
          {{ $t("jinrishouyi") }}</van-col>
      </van-row>
    </div>
  </div>
  <div style="height: 90px"></div>
</template>

<script>
import countTo from "@/components/vue-count-to/src/vue-countTo.vue";
export default {
  name: "my",
  components: { countTo },
  data() {
    return {
      change_price: 0,
      token_number: 0,
      address: "",
      utmsTotal: 0,
      utmsTodayTotal: 0,
      usdtTodayTotal: 0,
      totalPeople: 0,
      usdtTodayPeople: 0,
      utmsTodayPeople: 0,
      userUrl:'',

      usdt_tibibox:true,
      race_tibibox:false,
    };
  },
  methods: {
    getMoney() {
      if (this.address) {
        this.$request.post("nft/home/getUsdtMoney", {}).then((res) => {
          if (res.data.code === 200) {
            this.token_number = res.data.result.number;
          }
        });
      }
    },
    async getTrcWallet() {
      this.address = sessionStorage.getItem("address");
    },
    // 提币操作
    async auth() {
      return this.$toast(this.$t("zanweikaifang"));

      if (this.change_price == 0) {
        return this.$toast(this.$t("amountError"));
      }
      this.$request
        .post("/nft/scCoinLog/applyWithdraw", {
          amount: this.change_price,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.getMoney();
            this.change_price = 0;
            return this.$toast(res.data.message);
          } else {
            return this.$toast(res.data.message);
          }
        });
    },
    getCenterInfo() {
      if (this.address) {
        this.$request
          .post("nft/home/getCenterInfo", {
            amount: this.change_price,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.utmsTotal = res.data.result.utmsTotal;
              this.utmsTodayTotal = res.data.result.utmsTodayTotal;
              this.usdtTodayTotal = res.data.result.usdtTodayTotal;
              this.totalPeople = res.data.result.totalPeople;
              this.usdtTodayPeople = res.data.result.usdtTodayPeople;
              this.utmsTodayPeople = res.data.result.utmsTodayPeople;
              this.userUrl = res.data.result.userUrl;
            }
          });
      }
    },

    goEarning() {
      this.$router.push({ path: "/earning" });
    },
    goTeam() {
      this.$router.push({ path: "/team" });
    },
    goTibi() {
      this.$router.push({ path: "/widthw" });
    },
  },
  mounted() {
    this.getTrcWallet();
    this.getMoney();
    this.getCenterInfo();
    // console.log(window.ethereum);
  },
};
</script>

<style scoped>
</style>
