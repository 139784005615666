<template>
  <div style="padding: 30px; box-sizing: border-box">
    <div style="width: 100%">
      <!-- <img :src="require('@/assets/image/invite.png')" alt="" style="
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        " /> -->
        <img :src="qrUrl" alt="" style="
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        " />
      <div style="
          width: 100%;
          background-color: rgb(33, 34, 68);
          padding: 20px 20px;
          box-sizing: border-box;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        ">
        <van-row gutter="20">
          <van-col span="24">
            <van-row>
              <van-col span="12" style="color: #ffffff; font-weight: 500; font-size: 15px">
                地址:{{
                  address ?
                    address.substring(0, 4) +
                    "****" +
                    address.substring(address.length - 5) : "****"
                }}
              </van-col>
              <van-col span="12" style="color: #ffffff; font-weight: 500; font-size: 15px">
                邀请码:{{ invite_code }}
              </van-col>
              <!-- <van-col style="margin-top: 20px; color: #7080b3" span="24">
                RACE 新一代移动节点挖矿
              </van-col> -->
            </van-row>
          </van-col>
          <!-- <van-col span="12" style="text-align: right">
            <img :src="qrUrl" alt="" style="width: 100px; height: 100px" />
          </van-col> -->
        </van-row>
      </div>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
    ">
    <van-row @click="codeCopy">
      <van-col span="24" style="text-align: center">
        <img :src="require('@/assets/image/copy.png')" alt="" style="width: 50px; height: 50px" />
      </van-col>
      <van-col span="24" style="text-align: center"> 复制邀请码 </van-col>
    </van-row>
    <!-- <van-row style="margin-top: 30px">
      <van-col span="24" style="font-size: 1.125rem"> 邀请奖励 </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        1. 邀请并推荐您的朋友加入您将获得最多三层奖励.
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        2. 三层奖励为1级 (7%) 2级 (5%) 3级 (3%)
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        3. 每日奖励将根据您属下的每日挖矿产出计算.
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        示例：
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        用户A邀请用户B加入,用户B在挖矿当天产生100USDT收益,用户A将获得. 100*7%=7
        USDT 推荐奖励.
      </van-col>
    </van-row> -->

  </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "invite",
  data() {
    return {
      qrUrl: "",
      invite_code: "",
      tronweb: "",
      address: sessionStorage.getItem("address"),
    };
  },
  methods: {
    codeCopy() {
      // let url = this.$api.baseUrl + '/#/home?invite_code=' + this.invite_code
      let invite_code = this.invite_code
      let copyInput = document.createElement('input');//创建input元素
      document.body.appendChild(copyInput);//向页面底部追加输入框
      copyInput.setAttribute('value', invite_code);//添加属性，将url赋值给input元素的value属性
      copyInput.select();//选择input元素
      document.execCommand("Copy");//执行复制命令
      copyInput.remove();//删除动态创建的节点
      return this.$toast("复制成功");
    },
    async getTrcWallet() {
      this.tronweb = window.tronWeb;
      this.address = this.tronweb.defaultAddress.base58;
    },
    getUserInfo() {
      this.$request
        .get("/nft/scUser/getUserInfo", {
          address: sessionStorage.getItem("address"),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.invite_code = res.data.result.ownCode;
            const Qrcode = require("qrcode");
            Qrcode.toDataURL(
              this.$api.baseUrl + "/#/home?invite_code=" + this.invite_code,
              (err, url) => {
                this.qrUrl = url;
              }
            );
          }
        });
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>

<style scoped></style>
